<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>

      <!-- ? Branch -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Branch (शाखा)</span>
          </div>
          <v-select v-model="clientDetail.branchName" :items="branches" item-text="text" item-value="value"
            placeholder="Select Branch (शाखा)" outlined dense :rules="stringRules"></v-select>
        </v-col>
      </v-row>

      <!-- ? Types of Account -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Types of Account (खाताको किसिम)</span>
          </div>
          <v-select v-model="clientDetail.typesOfAccount" :items="typesOfAccount" item-text="text" item-value="value"
            placeholder="Select Types of Account (खाताको किसिम)" outlined dense :rules="stringRules"></v-select>
        </v-col>
      </v-row>

      <!-- !  Basic Details -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">Basic Details</span>
        </v-col>
      </v-row>

      <!-- ?  Text fields -->
      <!-- ? Full Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="text--darken-3 inputLabel">हित्ग्राही को नाम</span>
          </div>

          <v-text-field placeholder="e.g. राम बहादुर अमात्य" density="compact" :rules="stringRules"
            v-model="clientDetail.nameNP"></v-text-field>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <!-- ? Full Name -->
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">Name of Beneficial Owner</span>
            </div>

            <v-text-field placeholder="e.g. Ram Bahadur Maharjan" density="compact" :rules="stringRules"
              v-model="clientDetail.nameEN"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ? Date Of Birth -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <DatePicker label="Date of Birth (जन्म मिति) in A.D." type="AD" emitName="changeADdate"
            placeholder="A.D. (ई.स.)" @changeADdate="changeADdate"></DatePicker>
        </v-col>
      </v-row>

      <v-row dense justify="center" class="mt-n6">
        <v-col cols="12" sm="10" md="10">
          <span style="color: #f44336" v-if="ADerror">* Required</span>
        </v-col>
      </v-row>

      <!-- ? DOB IN BS -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <DatePicker label="Date of Birth (जन्म मिति) in B.S." type="BS" emitName="changeBSdate"
            placeholder="B.S. (बि.स.)" @changeBSdate="changeBSdate"></DatePicker>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <span style="color: #f44336" v-if="BSerror">* Required</span>
        </v-col>
      </v-row>

      <!-- ? Gender -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Gender</span>
          </div>
          <v-select v-model="clientDetail.gender" :items="gender" item-text="text" item-value="value"
            placeholder="Select Gender (लिङ्ग)" outlined dense :rules="stringRules"></v-select>
        </v-col>
      </v-row>

      <!-- ? Nationality -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-4">
            <span class="grey--text text--darken-3 inputLabel">Nationality (राष्ट्रियता)</span>
          </div>

          <v-autocomplete v-model="clientDetail.nationality" :items="nationalities" outlined dense :rules="stringRules"
            label="Select Nationality"></v-autocomplete>
        </v-col>
      </v-row>

      <!-- ?  Citizenship Number -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Citizenship Number
              </span>
            </div>

            <v-text-field dense placeholder="e.g. 12-23-21-3445" density="compact" v-model="clientDetail.citizenshipNo"
              :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ? Citizenship Issue District -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Issue District
              </span>
            </div>

            <v-text-field dense placeholder="e.g. Lalitpur" density="compact" v-model="clientDetail.citizenshipDistrict"
              :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ? Citizenship Issue Date-->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <DatePicker label="Citizenship Issue Date in B.S." type="BS" emitName="changeCitizenshipIssueDate"
            placeholder="B.S. (बि.स.)" @changeCitizenshipIssueDate="changeCitizenshipIssueDate"></DatePicker>
        </v-col>
      </v-row>

      <v-row dense justify="center" class="mt-n1" v-if="CitizenshipIssueDateError">
        <v-col cols="12" sm="10" md="10">
          <span style="color: #f44336">* Required</span>
        </v-col>
      </v-row>

      <!-- ?  Passport Number-->
      <v-row dense justify="center" class="mt-8">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="text--darken-3 inputLabel">Passport number</span>
          </div>

          <v-text-field placeholder="e.g. 1021221212" density="compact" v-model="clientDetail.passportNo"></v-text-field>
        </v-col>
      </v-row>

      <!-- ? Place of Issue -->
      <v-row dense justify="center" class="mt-7">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Place of Issue
              </span>
            </div>

            <v-text-field placeholder="e.g. Manbhawan" density="compact"
              v-model="clientDetail.passportPlace"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ? Passport Issue Date -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <DatePicker label="Passport Issue Date (जारी मिति) in ई.सं." type="AD" emitName="changePassportIssueDate"
            placeholder="A.D. (ई.स.) " @changePassportIssueDate="changePassportIssueDate"></DatePicker>
        </v-col>
      </v-row>

      <v-row dense justify="center" class="mt-n1" v-if="PassportIssueDate">
        <v-col cols="12" sm="10" md="10">
          <span style="color: #f44336">* Required</span>
        </v-col>
      </v-row>

      <!-- ? Passport Expiry Date -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <DatePicker label="Passport Expiry Date (जारी मिति) in ई.सं." type="AD" emitName="changePassportExpiryDate"
            placeholder="A.D. (ई.स.)" @changePassportExpiryDate="changePassportExpiryDate"></DatePicker>
        </v-col>
      </v-row>

      <v-row dense justify="center" class="mt-n1" v-if="PassportExpiryDateError">
        <v-col cols="12" sm="10" md="10">
          <span style="color: #f44336">* Required</span>
        </v-col>
      </v-row>

      <!-- ? Identity Card Type -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Type of Identity Card</span>
          </div>
          <v-text-field variant="outlined" placeholder="e.g. Pan Card, Driving License, etc." density="compact"
            v-model="clientDetail.idType"></v-text-field>
        </v-col>
      </v-row>

      <!-- ? Identification Number -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Identification Number</span>
          </div>
          <v-text-field variant="outlined" placeholder="e.g. 324234243" density="compact"
            v-model="clientDetail.idNo"></v-text-field>
        </v-col>
      </v-row>

      <!-- ?  Issuance Authority -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Issuance Authority
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Nepal Government" density="compact"
              v-model="clientDetail.idAuthority"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ? Identity Issue Date -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <DatePicker label="Identity Issue Date" type="AD" emitName="changeIdentityIssueDate" placeholder="A.D. (ई.स.)"
            @changeIdentityIssueDate="changeIdentityIssueDate"></DatePicker>
        </v-col>
      </v-row>

      <v-row dense justify="center" class="mt-n1" v-if="IdentityIssueDateError">
        <v-col cols="12" sm="10" md="10">
          <span style="color: #f44336">* Required</span>
        </v-col>
      </v-row>

      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">For Non Resident Nepalese</span>
        </v-col>
      </v-row>

      <!-- ?  NRN ID -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                NRN Code No.
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. 197897187" density="compact"
              v-model="clientDetail.nrnCode"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  NRN Address -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Foreign Address
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. XXX" density="compact"
              v-model="clientDetail.nrnAddress"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  NRN Address -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Country (For NRN)
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. XXX" density="compact"
              v-model="clientDetail.nrnCountry"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  NRN City -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                City (For NRN)
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. XXX" density="compact"
              v-model="clientDetail.nrnCity"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  NRN State -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                State/ Province (For NRN)
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. XXX" density="compact"
              v-model="clientDetail.nrnState"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- !  Occupation Details -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">पेसागत विवरण</span>
          <br />
          <span class="text--darken-3 inputTitle">Details of Occupation</span>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Occupation (पेशा)</span>
          </div>
          <v-select v-model="clientDetail.occupation" :items="occupation" item-text="text" item-value="value"
            placeholder="Select Occupation" outlined dense :rules="stringRules"></v-select>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Type of Business (व्यापारको प्रकार)</span>
          </div>
          <v-select v-model="clientDetail.businessType" :items="businessTypes" item-text="text" item-value="value"
            placeholder="Select business type" outlined dense></v-select>
        </v-col>
      </v-row>

      <!-- ?  Organization Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Organization’s Name (संस्थाको नाम)
              </span>
            </div>

            <v-text-field placeholder="e.g. Acme Pvt. Ltd." v-model="clientDetail.organizationName" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Organization Address -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Organization’s Address (ठेगाना)
              </span>
            </div>

            <v-text-field placeholder="e.g. Bakhundole, Lalitpur" v-model="clientDetail.organizationAddress" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Designation-->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Designation (पद)
              </span>
            </div>

            <v-text-field placeholder="e.g. Accountant" v-model="clientDetail.organizationDesignation" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Employee Id-->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Employee Id (कर्मचारीको हकमा कर्मचारी परिचयपत्र नं.)
              </span>
            </div>

            <v-text-field placeholder="e.g. 12321" v-model="clientDetail.employeeIDNo" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Financial Details-->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Financial Details (आर्थिक विवरण)
              </span>
              <br />

              <span class="grey--text text--darken-3 inputLabel">
                आयको सिमा (आर्थिक विवरण / Income Limit (Annual Details)
              </span>
            </div>

            <v-select v-model="clientDetail.incomeLimit" :items="incomeLimits" item-text="text" item-value="value"
              placeholder="Select Income Bracket" outlined dense :rules="stringRules"></v-select>
          </section>
        </v-col>
      </v-row>

      <!-- ? Automatic Transactions -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Standing Instruction for Automatic Transactions
              </span>
              <br />
              <span class="grey--text text--darken-3 inputLabel">
                निक्षेप सदस्यले हित्ग्राहीको खातामा भएको घातबढ स्वचालित रुपमा गराउने/ नगराउने।
              </span>
              <br />

              <v-radio-group v-model="clientDetail.automaticTransaction
                " column>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </div>
          </section>
        </v-col>
      </v-row>
      <!-- ? Account Statement -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Account Statement (खाताको विवरण प्राप्त गर्ने)
              </span>
              <br />
            </div>

            <v-select v-model="clientDetail.statementFrequency" :items="statementFrequency" item-text="text"
              item-value="value" placeholder="Select Statement Frequency" outlined dense :rules="stringRules"></v-select>
          </section>
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-btn color="success" @click="validate">Next</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { nationalities } from '@/config/nationalities';
import DatePicker from '@/components/DatePicker.vue';
import { getNames } from 'country-list';

export default {
  name: 'ClientDetails',
  components: {
    DatePicker,
  },
  data() {
    return {
      menu2: false,
      valid: true,
      BSerror: false,
      ADerror: false,
      countries: getNames(),
      IdentityIssueDateError: false,
      CitizenshipIssueDateError: false,
      PassportIssueDateError: false,
      PassportExpiryDateError: false,
      gender: [
        { text: 'Male (पुरुष)', value: 'Male' },
        { text: 'Female (महिला)', value: 'Female' },
      ],
      branches: [
        { text: 'Kathmandu', value: 'Kathmandu' },
        { text: 'Itahari', value: 'Itahari' },
        { text: 'Charikot', value: 'Charikot' },
        { text: 'Dhulikhel', value: 'Dhulikhel' },
        { text: 'Bardibas', value: 'Bardibas' },
        { text: 'Birgunj', value: 'Birgunj' },
        { text: 'Bhairahawa', value: 'Bhairahawa' },
        { text: 'Beni', value: 'Beni' },
        { text: 'Surkhet', value: 'Surkhet' },
        { text: 'Hetauda', value: 'Hetauda' },
      ],
      typesOfAccount: [
        { text: "Individual (व्यक्तिगत)", value: "Individual" },
        { text: "Non Resident Nepalese (गैर आवासीय नेपाली)", value: "Non Resident Nepalese" },
        { text: "Foreigner (विदेसी)", value: "Foreigner" },
      ],
      nationalities: nationalities,
      clientDetail: {
        branchName: '',
        typesOfAccount: '',
        nameNP: '',
        nameEN: '',
        dobNP: '',
        dobEN: '',
        gender: '',
        nationality: '',
        citizenshipNo: '',
        citizenshipDistrict: '',
        citizenshipDate: '',
        passportNo: '',
        passportPlace: '',
        passportDate: '',
        passportExpiry: '',
        idType: '',
        idNo: '',
        idAuthority: '',
        idDate: '',
        nrnCode: '',
        nrnAddress: '',
        nrnCountry: '',
        nrnCity: '',
        nrnState: '',
        occupation: '',
        organizationName: '',
        organizationAddress: '',
        employeeIDNo: '',
        incomeLimit: '',
        businessType: '',
        organizationDesignation: '',
        automaticTransaction: '',
        statementFrequency: ''

      },
      occupation: [
        { text: 'Service (सेवा)', value: 'Service' },
        { text: 'Government (सरकारी)', value: 'Government' },
        {
          text: 'Public/Private Sector (सामाजिक/ निजी क्षेत्र)',
          value: 'Public/ Private Sector',
        },
        {
          text: 'NGO/INGO (एन.जी.ओ./आई.न.जी.ओ)',
          value: 'NGO/INGO',
        },
        {
          text: 'Expert (विशेषज्ञ)',
          value: 'Expert',
        },
        {
          text: 'Businessperson (व्यापार)',
          value: 'Businessperson',
        },
        {
          text: 'Agriculture (कृषि)',
          value: 'Agriculture',
        },
        {
          text: 'Retired (सेवा निवृत्त)',
          value: 'Retired',
        },
        {
          text: 'Housewife (गृहिणी)',
          value: 'Housewife',
        },
        {
          text: 'Student (विद्यार्थी)',
          value: 'Student',
        },
        {
          text: 'Others (अन्य)',
          value: 'Others',
        },
      ],
      statementFrequency: [
        {
          text: 'Daily (दैनिक)',
          value: 'Daily'
        },
        {
          text: 'Weekly (साप्ताहिक)',
          value: 'Weekly'
        },
        {
          text: '15 Days (पाक्षिक)',
          value: '15 Days'
        },
        {
          text: 'Monthly (मासिक)',
          value: 'Monthly'
        },
      ],
      incomeLimits: [
        {
          text: 'Upto Rs. 1,00,000 (रु. १,००,००० सम्म)',
          value: '< 100000',
        },
        {
          text: 'From Rs. 1,00,001 to Rs. 2,00,000 (रु. १,००,००१ देखि रु २,००,००० सम्म)',
          value: '100001 < > 200000',
        },
        {
          text: 'From 2,00,001 to Rs. 5,00,000 (रु. २,००,००१ देखि रु. ५,००,००० सम्म)',
          value: '2000001 < > 500000',
        },
        {
          text: 'Above Rs. 5,00,000 (रु. ५,००,००० भन्दा माथि)',
          value: '500000 <',
        },
      ],
      businessTypes: [
        {
          text: 'Manufacturing (उत्पादन)',
          value: 'Manufacturing',
        },
        {
          text: 'Service Oriented (सेवामुखी)',
          value: 'Service Oriented',
        },
        {
          text: 'Others (अन्य)',
          value: 'Others',
        },
      ],
      stringRules: [(v) => !!v || 'Field is required'],
      floatRules: [
        (v) => !!v || 'Field is required',
        (v) => /[+-]?([0-9]*[.])?[0-9]+/.test(v) || 'Field must be a number',
      ],
      integerRules: [
        (v) => !!v || 'Field is required',
        (v) =>
          /^[+]?\d+?$/.test(v) || 'Field must be a number without decimals',
      ],
    };
  },
  methods: {
    changeADdate(value) {
      this.clientDetail.dobEN = value;
    },
    changeBSdate(value) {
      this.clientDetail.dobNP = value;
    },
    changePassportIssueDate(value) {
      this.clientDetail.passportDate = value;
    },
    changePassportExpiryDate(value) {
      this.clientDetail.passportExpiry = value;
    },
    changeCitizenshipIssueDate(value) {
      this.clientDetail.citizenshipDate = value;
    },
    changeIdentityIssueDate(value) {
      this.clientDetail.idDate = value;
    },
    validate() {
      if (this.clientDetail.dobEN === null) {
        this.ADerror = true;
      } else {
        this.ADerror = false;
      }

      if (this.clientDetail.dobNP === null) {
        this.BSerror = true;
      } else {
        this.BSerror = false;
      }

      if (this.clientDetail.citizenshipDate === null) {
        this.CitizenshipIssueDateError = true;
      } else {
        this.CitizenshipIssueDateError = false;
      }

      if (this.clientDetail.passportDate === null) {
        this.PassportIssueDateError = true;
      } else {
        this.PassportIssueDateError = false;
      }

      if (this.clientDetail.passportExpiry === null) {
        this.PassportExpiryDateError = true;
      } else {
        this.PassportExpiryDateError = false;
      }

      if (this.clientDetail.idDate === null) {
        this.IdentityIssueDateError = true;
      } else {
        this.IdentityIssueDateError = false;
      }

      if (
        this.$refs.form.validate() &&
        this.clientDetail.dobEN !== null &&
        this.clientDetail.dobNP !== null &&
        this.clientDetail.citizenshipDate !== null
      ) {
        this.$emit('changeDetails', {
          data: this.clientDetail,
          type: 'Client Details',
        });
        this.$emit('nextStep');
      } else {
        this.$emit('errorMessage', 'Invalid or missing fields');
      }
    },
  },
};
</script>

<style>
.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}
</style>
