<template>
  <v-container class="my-10 mb-8 pb-8 kyc_form">
    <v-dialog v-model="submitDialog" max-width="500px">
      <v-card>
        <v-card-title>Your form has been submitted</v-card-title>
        <v-card-text>
          <p>You will get a copy of your form in the email you provided</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="submitLoader" max-width="500px">
      <v-card>
        <v-card-title>Your form is being submitted</v-card-title>
        <v-card-text>
          <p>Your form is being processed. This might take some time...</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <h2><v-btn class="me-2" icon href="javascript:history.back()"><v-icon>mdi-chevron-left</v-icon></v-btn>DEMAT
      Account Form</h2>

    <v-divider class="my-8"> </v-divider>

    <v-snackbar v-model="snackBar.show" :timeout="3000" :color="snackBar.color" elevation="24" location="bottom right">
      <span class="snackbar__message">{{ snackBar.text }}</span>
    </v-snackbar>

    <template>
      <v-stepper v-model="e2">
        <!-- <v-stepper-header :v-for="(header, index) in stepperHeaders"> -->
        <v-stepper-header>
          <template v-for="(header, index) in stepperHeaders">
            <!-- ? Headers of the Steppers -->
            <v-stepper-step :complete="e2 > index" :step="++index" :key="index + header">
              {{ header }}
            </v-stepper-step>
            <v-divider v-if="index !== stepperHeaders.length" :key="index"></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <ClientDetails style="margin: 2rem 0rem" @nextStep="nextStep" @changeDetails="handleChangeDetails($event)"
              @errorMessage="showErrorMessage" />
          </v-stepper-content>

          <v-stepper-content step="2">
            <Address style="margin: 2rem 0rem" @nextStep="nextStep" @previousStep="previousStep"
              @changeDetails="handleChangeDetails($event)" @errorMessage="showErrorMessage" />
          </v-stepper-content>

          <v-stepper-content step="3">
            <FamilyMembers style="margin: 2rem 0rem" @nextStep="nextStep" @previousStep="previousStep"
              @changeDetails="handleChangeDetails($event)" @errorMessage="showErrorMessage" />
          </v-stepper-content>

          <v-stepper-content step="4">
            <BankAccount style="margin: 2rem 0rem" @nextStep="nextStep" @previousStep="previousStep"
              @changeDetails="handleChangeDetails($event)" @errorMessage="showErrorMessage" />
          </v-stepper-content>

          <v-stepper-content step="5">
            <Documents style="margin: 2rem 0rem" @nextStep="nextStep" @previousStep="previousStep"
              @changeDetails="handleChangeDetails($event)" @errorMessage="showErrorMessage" @submit="submit" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </v-container>
</template>

<script>
import ClientDetails from '@/components/DEMAT/ClientDetails.vue';
import Address from '@/components/DEMAT/Address.vue';
import FamilyMembers from '@/components/DEMAT/FamilyMembers.vue';
import Documents from '../components/DEMAT/Documents.vue';
import BankAccount from '../components/DEMAT/BankAccount.vue';

// * Services
import IndividualDEMATService from '../services/API/IndividualDEMATService';

export default {
  name: 'DEMAT',
  components: {
    ClientDetails,
    Address,
    FamilyMembers,
    Documents,
    BankAccount,
  },
  data: () => ({
    submitDialog: false,
    submitLoader: false,
    snackBar: {
      show: false,
      color: 'success',
    },
    e2: 1,
    stepperHeaders: [
      'Client Details',
      'Address Details',
      'Family Members',
      'Bank Account Details',
      'Documents Upload',
    ],
    formData: {
      clientDetail: {
        fullNameNP: null,
        fullName: null,
        dobInBS: null,
        dobInAD: null,
        gender: null,
        nationality: null,
        citizenshipNo: null,
        issueDistrict: null,
        issueDate: null,
        beneficiaryIDNo: null,
        panNumber: null,
        nrnId: null,
        nrnAddress: null,
        occupationDetail: {
          occupation: null,
          businessType: null,
          organizationName: null,
          organizationAddress: null,
          designation: null,
          employeeId: null,
        },
      },
      address: {
        currentAddress: {
          country_: null,
          province_: null,
          district_: null,
          municipality_: null,
          wardNo_: null,
          tole_: null,
          telephoneNo_: null,
          email_: null,
          mobileNo_: null,
        },
        permanentAddress: {
          province: null,
          district: null,
          municipality: null,
          tole: null,
          telephoneNo: null,
          wardNo: null,
          blockNo: null,
        },
      },
      familyMembers: {
        grandFatherName: null,
        grandFatherNameNP: null,
        fatherName: null,
        fatherNameNP: null,
        motherName: null,
        motherNameNP: null,
        spouseName: null,
        spouseNameNP: null,
        sonName: null,
        sonNameNP: null,
        daughterName: null,
        daughterNameNP: null,
        daughterInLawNameNP: null,
        fatherInLawName: null,
        fatherInLawNameNP: null,
        motherInLawName: null,
        motherInLawNameNP: null,
      },
      bankAccount: {
        bankAccountType: '',
        bankAccountNumber: '',
        bankNameAddress: '',
      },
      documents: {
        photoPP: null,
        map: null,
        guardianSignature: null,
        clientSignature: null,
        rightThumbPrint: null,
        leftThumbPrint: null,
        idCertificate: null,
        guardianPP: null,
        panCardImage: null,
        bankChequeImage: null,
        employeeIDImage: null,
        electricityWaterBillImage: null,
        passportImage: null,
      },
    },

    stringRules: [(v) => !!v || 'Field is required'],
    floatRules: [
      (v) => !!v || 'Field is required',
      (v) => /[+-]?([0-9]*[.])?[0-9]+/.test(v) || 'Field must be a number',
    ],
    integerRules: [
      (v) => !!v || 'Field is required',
      (v) => /^[+]?\d+?$/.test(v) || 'Field must be a number without decimals',
    ],
  }),
  watch: {
    submitDialog: (value) => {
      if (!value) {
        window.location.reload();
      }
    },
  },
  methods: {
    nextStep() {
      this.e2++;
    },
    previousStep() {
      this.e2--;
    },
    handleChangeDetails(data) {
      if (data.type === 'Client Details') {
        this.formData.clientDetail = data.data;
      } else if (data.type === 'Address Details') {
        this.formData.address = data.data;
      } else if (data.type === 'Family Member Details') {
        this.formData.familyMembers = data.data;
      } else if (data.type === 'Document Details') {
        this.formData.documents = data.data;
      } else if (data.type === 'Bank Details') {
        this.formData.bankAccount = data.data;
      }
    },
    showErrorMessage(text) {
      this.snackBar.show = true;
      this.snackBar.color = 'error';
      this.snackBar.text = text;
    },
    closeDialogForm() {
      this.dialog = false;
      this.newTaskMaterial = {
        materialId: null,
        vendorRate: null,
        clientRate: null,
      };
    },
    async submit() {
      this.submitLoader = true;
      await IndividualDEMATService.FillIndividualDEMATForm(this.formData).then(
        () => {
          this.submitLoader = false;
          this.submitDialog = true;
        }
      );
    },

    validate() {
      localStorage.setItem('taskFormData', JSON.stringify(this.formData));
    },
  },
};
</script>

<style scoped>
/* Custom CSS to reduce the gap between stepper headers */
.kyc_form {
  font-family: 'Montserrat', sans-serif !important;
}

.snackbar__message {
  font-weight: 200 !important;
  font-size: 1rem;
}

.custom-input {
  margin-top: 1rem;

  font-family: 'Inter', sans-serif !important;
  font-size: 1rem;

  border-radius: 0.2em;

  outline: none;
}

.cardTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}
</style>
