<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- !  Bank Account Details -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">बैंक खाताको विवरण</span>
          <br />
          <span class="text--darken-3 inputTitle">Bank Account Details</span>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <div class="mb-2">
            <span class="grey--text text--darken-3 inputLabel">Types of Bank Account (बैंक खाताको किसिम)</span>
          </div>
          <v-select v-model="bankAccount.accountType" :items="accountType" item-text="text" item-value="value"
            placeholder="Select Bank Account Type" outlined dense :rules="stringRules"></v-select>
        </v-col>
      </v-row>

      <!-- ?  Bank Account Number -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Bank Account Number (बैंक खाता नम्बर)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 123456789" v-model="bankAccount.bankAccountNo" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Bank Address -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Bank Name and Address (बैंक खाता भएको बैंकको ठेगाना)
              </span>
            </div>

            <v-text-field placeholder="e.g. Acme Bank, New Baneshwor" v-model="bankAccount.bankNameAddress" />
          </section>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-btn color="success" outlined class="mr-4" @click="goBack">Back</v-btn>
          <v-btn color="success" @click="validate">Next</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'BankAccount',
  data() {
    return {
      accountType: [
        { text: 'Savings Account', value: 'Savings Account' },
        { text: 'Current Account', value: 'Current Account' },
      ],
      valid: true,
      bankAccount: {
        accountType: '',
        bankAccountNo: '',
        bankNameAddress: '',
      },
      stringRules: [(v) => !!v || 'Field is required'],
      floatRules: [
        (v) => !!v || 'Field is required',
        (v) => /[+-]?([0-9]*[.])?[0-9]+/.test(v) || 'Field must be a number',
      ],
      integerRules: [
        (v) => !!v || 'Field is required',
        (v) =>
          /^[+]?\d+?$/.test(v) || 'Field must be a number without decimals',
      ],
    };
  },
  methods: {
    goBack() {
      this.$emit('previousStep');
    },
    validate() {
      // Remove This
      if (this.$refs.form.validate()) {
        this.$emit('changeDetails', {
          data: this.bankAccount,
          type: 'Bank Details',
        });
        this.$emit('nextStep');
      } else {
        this.$emit('errorMessage', 'Invalid or missing fields');
      }
    },
  },
};
</script>

<style>
.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}
</style>
