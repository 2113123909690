import APIManager from './APIManager';

const FillIndividualDEMATForm = async (queryObj) => {
    // ? Flattening the object for form data
    console.log('Query Obj:', queryObj);
    let occupationDetail = queryObj.clientDetail.occupationDetail;
    delete queryObj.clientDetail.occupationDetail;
    let familyDetails = queryObj.familyMembers;
    let documentDetails = queryObj.documents;
    let requiredData = {
        ...occupationDetail,
        ...queryObj.clientDetail,
        ...queryObj.address,
        ...familyDetails,
        ...queryObj.bankAccount,
        ...documentDetails,
    };
    const formData = new FormData();
    for (const key in requiredData) {
        if (Object.prototype.hasOwnProperty.call(requiredData, key)) {
            formData.append(key, requiredData[key]);
        }
    }

    console.log(requiredData, formData);
    const formResponse = APIManager.axios
        .post(`/api/v1/public/individual-demat`, formData)
        .then((response) => {
            return response;
        });

    return formResponse;
};

export default {
    FillIndividualDEMATForm,
};
