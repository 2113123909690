<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- !  Current Address -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">Address</span>
        </v-col>
      </v-row>

      <!-- ?  Correspondence Address -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Correspondence Address
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Mangalbazar-11, Lalitpur" density="compact"
              v-model="address.correspondenceAddress" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Country -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Country
              </span>
            </div>

            <v-autocomplete v-model="address.country_" :items="countries" outlined dense :rules="stringRules"
              label="Select Country"></v-autocomplete>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Zone -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel"> Zone/ Province </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Bagmati" density="compact" v-model="address.zone_"
              :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  District -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                District
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Lalitpur" density="compact" v-model="address.district_"
              :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Municipality -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Rural Municipality/ Municipality/ Sub Metropolitan City/
                Metropolitan City <br />
                (गा.पा./ न.पा./ उ.म.न.पा./ म.न.पा.)
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Patan" density="compact" v-model="address.municipality_"
              :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Tole -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel"> Tole </span>
            </div>

            <v-text-field type="string" placeholder="e.g. 12" v-model="address.tole_" density="compact"
              :rules="stringRules" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Ward Number -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Ward Number (वडा न.)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="address.wardNo_" density="compact"
              :rules="integerRules" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Block No -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Block No.
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. 12" density="compact"
              v-model="address.blockNo_"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Telephone No -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Telephone Number
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 5534985" v-model="address.telephoneNo_" density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mobile No -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mobile Number
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 9827372837" v-model="address.mobileNo_" density="compact"
              :rules="integerRules" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Email -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Email Address
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. example@gmail.com" density="compact"
              v-model="address.emailID_" :rules="emailRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Fax No -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Fax Number
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12233" v-model="address.faxNo_" density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- !  Permanent Address -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">Permanent Address</span>
        </v-col>
      </v-row>

      <!-- ?  Correspondence Address -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Permanent Address
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Mangalbazar-11, Lalitpur" density="compact"
              v-model="address.permanentAddress" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Country -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Country
              </span>
            </div>

            <v-autocomplete v-model="address.country" :items="countries" outlined dense :rules="stringRules"
              label="Select Country"></v-autocomplete>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Zone -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel"> Zone/ Province </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Bagmati" density="compact" v-model="address.zone"
              :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  District -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                District
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Lalitpur" density="compact" v-model="address.district"
              :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Municipality -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Rural Municipality/ Municipality/ Sub Metropolitan City/
                Metropolitan City <br />
                (गा.पा./ न.पा./ उ.म.न.पा./ म.न.पा.)
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Patan" density="compact" v-model="address.municipality"
              :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Tole -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel"> Tole </span>
            </div>

            <v-text-field type="string" placeholder="e.g. 12" v-model="address.tole" density="compact"
              :rules="stringRules" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Ward Number -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Ward Number (वडा न.)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="address.wardNo" density="compact"
              :rules="integerRules" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Block No -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Block No.
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. 12" density="compact"
              v-model="address.blockNo"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Telephone No -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Telephone Number
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 5534985" v-model="address.telephoneNo" density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mobile No -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mobile Number
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 9827372837" v-model="address.mobileNo" density="compact"
              :rules="integerRules" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Fax No -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Fax Number
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12233" v-model="address.faxNo" density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Email -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Email Address
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. example@gmail.com" density="compact"
              v-model="address.emailID" :rules="emailRules"></v-text-field>
          </section>
        </v-col>
      </v-row>
      <!-- ?  Nearest Landmark -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Nearest Landmark
              </span>
            </div>

            <v-text-field variant="outlined" density="compact" v-model="address.nearestLandmark"
              :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-btn color="success" outlined class="mr-4" @click="goBack">Back</v-btn>
          <v-btn color="success" @click="validate">Next</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { getNames } from 'country-list';

export default {
  name: 'Address',

  data() {
    return {
      valid: true,
      countries: getNames(),
      address: {
        country_: '',
        tole_: '',
        correspondenceAddress: '',
        district_: '',
        zone_: '',
        municipality_: '',
        wardNo_: '',
        blockNo_: '',
        telephoneNo_: '',
        emailID_: '',
        mobileNo_: '',
        faxNo_: '',
        permanentAddress: '',
        zone: '',
        district: '',
        municipality: '',
        tole: '',
        blockNo: '',
        wardNo: '',
        telephoneNo: '',
        mobileNo: '',
        faxNo: '',
        emailID: '',
        nearestLandmark: '',
      },
      stringRules: [(v) => !!v || 'Field is required'],
      emailRules: [
        (v) => !!v || 'Field is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      floatRules: [
        (v) => !!v || 'Field is required',
        (v) => /[+-]?([0-9]*[.])?[0-9]+/.test(v) || 'Field must be a number',
      ],
      integerRules: [
        (v) => !!v || 'Field is required',
        (v) =>
          /^[+]?\d+?$/.test(v) || 'Field must be a number without decimals',
      ],
    };
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('changeDetails', {
          data: this.address,
          type: 'Address Details',
        });
        this.$emit('nextStep');
      } else {
        this.$emit('errorMessage', 'Invalid or missing fields');
      }
    },
    goBack() {
      this.$emit('previousStep');
    },
  },
};
</script>

<style>
.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}
</style>
