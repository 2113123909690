<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- !  familyMembers Members -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">Details of Family Members</span>
        </v-col>
      </v-row>

      <!-- ?  Grand Father Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Grand Father's Name
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Ram Bahadur Amatya" density="compact"
              v-model="familyMembers.grandFatherNameEN" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Grand Father Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Grand Father's Name in Nepali (बजेको नाम)
              </span>
            </div>

            <v-text-field placeholder="e.g. राम बहादुर अमात्य" v-model="familyMembers.grandFatherNameNP" density="compact"
              :rules="stringRules" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Father Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Father's Name
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Ram Bahadur Amatya" density="compact"
              v-model="familyMembers.fatherNameEN" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?   Father Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Father's Name in Nepali
              </span>
            </div>

            <v-text-field placeholder="e.g. राम बहादुर अमात्य" v-model="familyMembers.fatherNameNP" density="compact"
              :rules="stringRules" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mother Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mother's Name
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Sita Bahadur Amatya" density="compact"
              v-model="familyMembers.motherNameEN" :rules="stringRules"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mother Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mother's Name in Nepali
              </span>
            </div>

            <v-text-field placeholder="e.g. सिता बहादुर अमात्य" v-model="familyMembers.motherNameNP"
              :rules="stringRules" />
          </section>
        </v-col>
      </v-row>

      <!-- ? Spouse Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Spouse's Name
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Sita Bahadur Amatya" density="compact"
              v-model="familyMembers.spouseNameEN"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Spouse Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Spouse's Name in Nepali
              </span>
            </div>

            <v-text-field placeholder="e.g. सिता बहादुर अमात्य" v-model="familyMembers.spouseNameNP" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Son Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Son's Name
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Ram Bahadur Amatya" v-model="familyMembers.sonNameEN"
              hint="* Use comma to separate multiple names. e.g. Ram Bahadur Amatya, Shyam Bahadur Amatya"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Son Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Son's Name in Nepali
              </span>
            </div>

            <v-text-field placeholder="e.g. राम बहादुर अमात्य" v-model="familyMembers.sonNameNP"
              hint="* Use comma to separate multiple names. e.g. राम बहादुर अमात्य, श्याम बहादुर अमात्य" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Daughter Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Daughter's Name
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Sita Amatya" v-model="familyMembers.daughterNameEN"
              hint="* Use comma to separate multiple names. e.g. Sita Amatya, Gita Amatya"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Daughter Name in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Daughter's Name in Nepali
              </span>
            </div>

            <v-text-field placeholder="e.g. सिता बहादुर अमात्य" v-model="familyMembers.daughterNameNP"
              hint="* Use comma to separate multiple names. e.g. सिता बहादुर अमात्य, गीता बहादुर अमात्य" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Daughter in law Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Daughter in Law's Name
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Sita Amatya"
              v-model="familyMembers.daughterInLawNameEN"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Daughter in law in Nepali -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Daughter in Law's Name in Nepali
              </span>
            </div>

            <v-text-field placeholder="e.g. सिता बहादुर अमात्य" v-model="familyMembers.daughterInLawNameNP" />
          </section>
        </v-col>
      </v-row>

      <!-- !  Guardian Members -->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle">संरक्षकको विवरण (नाबालक को हकमा मात्र)</span>
          <br />
          <span class="text--darken-3 inputTitle">
            Guardian’s Details (In case of Minor only)</span>
        </v-col>
      </v-row>

      <!-- ?  Guardians Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Guardian Name
              </span>
            </div>

            <v-text-field placeholder="e.g. Rukhmani Amatya" v-model="familyMembers.guardianName" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Guardians Relationship -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Relationship with applicant (निवेदकसंगको सम्बन्ध)
              </span>
            </div>

            <v-text-field placeholder="e.g. Mother" v-model="familyMembers.guardianRelation" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Guardians Address -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Correspondence Address (पत्राचार ठेगाना)
              </span>
            </div>

            <v-text-field placeholder="e.g. Mangalbazar, Lalitpur" v-model="familyMembers.guardianAddress" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Guardian Country -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Country (देश)
              </span>
            </div>

            <v-autocomplete v-model="familyMembers.guardianCountry" :items="countries" outlined dense
              label="Select Country"></v-autocomplete>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Zone -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel"> Zone/ Province </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Bagmati" density="compact"
              v-model="familyMembers.guardianZone"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  District -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                District
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Lalitpur" density="compact"
              v-model="familyMembers.guardianDistrict"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Telephone no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Telephone No. (टेलिफोन नं.)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="familyMembers.guardianTelephone"
              density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Fax no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Fax No. (फ्याक्स नं.)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="familyMembers.guardianFax" density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mobile no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mobile No. (मोबाईल नं.)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="familyMembers.guardianMobile" density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  PAN no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                PAN No. (स्थायी लेखा नम्बर)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="familyMembers.guardianPAN" density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Email Address  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                E-Mail ID (ईमेल)
              </span>
            </div>

            <v-text-field type="email" placeholder="e.g. 12" v-model="familyMembers.guardianEmail" density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- !  Nominee Details-->
      <!-- ? Label -->
      <v-row dense justify="center" class="mb-4">
        <v-col cols="12" sm="10" md="10">
          <span class="text--darken-3 inputTitle"> Nominee’s Details</span>
        </v-col>
      </v-row>

      <!-- ?  Nominees Name -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Name/Surname (नाम/ थर)
              </span>
            </div>

            <v-text-field placeholder="e.g. Rukhmani Amatya" v-model="familyMembers.nomineeName" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Nominees Relationship -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Relationship with applicant (निवेदकसंगको सम्बन्ध)
              </span>
            </div>

            <v-text-field placeholder="e.g. Mother" v-model="familyMembers.nomineeRelation" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Nominees Citizenship / Passport -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Citizenship / Passport No
              </span>
            </div>

            <v-text-field placeholder="e.g. 32423423443" v-model="familyMembers.nomineeID" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Nominee Place of Issue -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Place of Issue
              </span>
            </div>

            <v-text-field placeholder="e.g. Kathmandu" v-model="familyMembers.nomineeIDIssue" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Nominee Age-->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Nominee Age
              </span>
            </div>

            <v-text-field placeholder="e.g. 21" v-model="familyMembers.nomineeAge" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Nominees Address -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Correspondence Address (पत्राचार ठेगाना)
              </span>
            </div>

            <v-text-field placeholder="e.g. Mangalbazar, Lalitpur" v-model="familyMembers.nomineeAddress" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Nominee Country -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Country (देश)
              </span>
            </div>

            <v-autocomplete v-model="familyMembers.nomineeCountry" :items="countries" outlined dense
              label="Select Country"></v-autocomplete>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Zone -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel"> Zone/ Province </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Bagmati" density="compact"
              v-model="familyMembers.nomineeZone"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  District -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                District
              </span>
            </div>

            <v-text-field variant="outlined" placeholder="e.g. Lalitpur" density="compact"
              v-model="familyMembers.nomineeDistrict"></v-text-field>
          </section>
        </v-col>
      </v-row>

      <!-- ?  Telephone no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Telephone No. (टेलिफोन नं.)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="familyMembers.nomineeTelephone"
              density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Fax no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Fax No. (फ्याक्स नं.)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="familyMembers.nomineeFax" density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Mobile no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                Mobile No. (मोबाईल नं.)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="familyMembers.nomineeMobile" density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  PAN no  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                PAN No. (स्थायी लेखा नम्बर)
              </span>
            </div>

            <v-text-field type="number" placeholder="e.g. 12" v-model="familyMembers.nomineePAN" density="compact" />
          </section>
        </v-col>
      </v-row>

      <!-- ?  Email Address  -->
      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <section class="pb-2">
            <div class="mb-2">
              <span class="grey--text text--darken-3 inputLabel">
                E-Mail ID (ईमेल)
              </span>
            </div>

            <v-text-field type="email" placeholder="e.g. haridahal@gmail.com" v-model="familyMembers.nomineeEmail"
              density="compact" />
          </section>
        </v-col>
      </v-row>

      <v-row dense justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-btn color="success" outlined class="mr-4" @click="goBack">Back</v-btn>
          <v-btn color="success" @click="validate">Next</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { getNames } from 'country-list';
export default {
  name: 'FamilyMembers',

  data() {
    return {
      valid: true,
      countries: getNames(),
      familyMembers: {
        grandFatherNameEN: '',
        grandFatherNameNP: '',
        fatherNameEN: '',
        fatherNameNP: '',
        motherNameEN: '',
        motherNameNP: '',
        spouseNameEN: '',
        spouseNameNP: '',
        sonNameEN: '',
        sonNameNP: '',
        daughterNameEN: '',
        daughterNameNP: '',
        daughterInLawNameEN: '',
        daughterInLawNameNP: '',
        guardianName: '',
        guardianRelation: '',
        guardianAddress: '',
        guardianCountry: '',
        guardianZone: '',
        guardianDistrict: '',
        guardianFax: '',
        guardianTelephone: '',
        guardianMobile: '',
        guardianPAN: '',
        guardianEmail: '',
        nomineeRelation: '',
        nomineeID: '',
        nomineeIDIssue: '',
        nomineeAge: '',
        nomineeAddress: '',
        nomineeCountry: '',
        nomineeZone: '',
        nomineeDistrict: '',
        nomineeTelephone: '',
        nomineeFax: '',
        nomineeMobile: '',
        nomineePAN: '',
        nomineeEmail: '',
      },
      stringRules: [(v) => !!v || 'Field is required'],
      floatRules: [
        (v) => !!v || 'Field is required',
        (v) => /[+-]?([0-9]*[.])?[0-9]+/.test(v) || 'Field must be a number',
      ],
      integerRules: [
        (v) => !!v || 'Field is required',
        (v) =>
          /^[+]?\d+?$/.test(v) || 'Field must be a number without decimals',
      ],
    };
  },

  methods: {
    validate() {
      // ! remove not
      if (this.$refs.form.validate()) {
        this.$emit('changeDetails', {
          data: this.familyMembers,
          type: 'Family Member Details',
        });
        this.$emit('nextStep');
      } else {
        this.$emit('errorMessage', 'Invalid or missing fields');
      }
    },
    goBack() {
      this.$emit('previousStep');
    },
  },
};
</script>

<style>
.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}
</style>
